import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Token, TokenInfo, TokenPermission } from 'services/cerbereTypes'
import TokenServices from 'services/TokenServices'

export interface TokenState {
  tokens: Token[];
  tokensLoading: boolean;
  tokenSearch: string;
  tokenInfo: TokenInfo;
  showToken: boolean;
  tokenInfoLoading: boolean;
  permissions: TokenPermission[];
  askPermissions: string[];
  selectedToken: Token | undefined;
}

const initialState: TokenState = {
  tokens: [],
  tokensLoading: true,
  tokenSearch: '',
  tokenInfo: { permissions: [], warning: '' },
  showToken: false,
  tokenInfoLoading: false,
  permissions: [],
  askPermissions: [],
  selectedToken: undefined,
}

export const tokensSlice = createSlice({
  name: 'tokens',
  initialState,
  reducers: {
    setTokenSearch: (state, action: PayloadAction<string>) => {
      state.tokenSearch = action.payload
    },
    setTokenModal: (state, action: PayloadAction<boolean>) => {
      state.showToken = action.payload
    },
    setTokenPermissions: (state, action: PayloadAction<string>) => {
      const index = state.askPermissions.indexOf(action.payload)
      if (index !== -1) {
        state.askPermissions.splice(index, 1)
      } else {
        state.askPermissions.push(action.payload)
      }
    },
    resetToken: state => {
      state.askPermissions = []
    },
    modifyToken: (state, action: PayloadAction<Token | undefined>) => {
      state.selectedToken = action.payload
    },
  },
  extraReducers: builder => {
    builder.addCase(TokenServices.getTokens.fulfilled, (state, action) => {
      state.tokens = action.payload
      state.tokensLoading = false
    })
    builder.addCase(TokenServices.getTokens.pending, state => {
      state.tokensLoading = true
    })
    builder.addCase(TokenServices.getTokenInfo.fulfilled, (state, action) => {
      state.tokenInfo = action.payload
      state.askPermissions = action.payload.permissions.map(perm => perm.role_name)
      state.tokenInfoLoading = false
    })
    builder.addCase(TokenServices.getTokenInfo.pending, state => {
      state.tokenInfoLoading = true
    })
    builder.addCase(TokenServices.getPermissions.fulfilled, (state, action) => {
      state.permissions = action.payload
    })
  },
})

export const {
  setTokenSearch, setTokenModal, setTokenPermissions, resetToken, modifyToken,
} = tokensSlice.actions

export default tokensSlice.reducer
