/* eslint-disable max-len */
export const terms = {
  Common: {
    copy: 'Copier',
    copied: 'Copié !',
    pendingQuery: 'Requête en cours...',
    add: 'Ajouter',
    to: 'aux',
    warning: 'Attention',
    ok: 'OK',
    noResults: 'Pas de résultats',
    loading: 'Chargement',
    charLimit: (nb: number) => `(${nb} caractères maximum)`,
    confirm: 'Confirmer',
    confirmation: 'Confirmation',
    cancel: 'Annuler',
    success: 'Succès',
    failure: 'Erreur',
    pending: 'En cours',
    continue: 'Continuer',
    back: 'Retour',
  },
  Error: {
    forbidden: {
      appTitle: "Vous n'avez pas les droits nécessaires pour accéder à cette application",
      appSubtitle: 'Veuillez contacter votre administrateur',
      content: 'Vous n\'avez pas les droits pour accéder à cette ressource. Veuillez réessayer ou contacter votre administrateur.',
      exitButton: 'Retour au portail des applications',
    },
    server: 'Une erreur serveur est survenue. Veuillez réessayer. (Code: 500)',
    conflict: 'Cet objet est en conflit. Réglez le conflit et réessayez.',
    unknown: 'Une erreur est survenue.',
    notFound: 'Une erreur serveur est survenue. Veuillez réessayer. (Code: 404)',
    badRequest: 'Une erreur est survenue. Vérifiez les informations que vous avez entrées et réessayez.',
  },
  Table: {
    slug: 'Identifiant',
    username: 'Identifiant',
    email: 'E-mail',
    firstName: 'Prénom',
    lastName: 'Nom',
    enabled: 'Actif',
    createdTimestamp: 'Date d\'enregistrement',
    id: 'ID',
    can_delegate: 'Rôle',
    action: 'Action',
    actions: 'Actions',
    users: 'Utilisateurs',
    token_name: 'Nom du token',
    type: 'Type',
    expires_on: 'Date d\'expiration',
    jwt: 'JWT',
    status: 'Statut',
    noResults: 'Pas de résultats correspondant à votre recherche',
    permission: 'Permissions',
    role_name: 'Rôle',
    doc_link: 'Lien vers la documentation',
    user: 'Utilisateur',
    comment: 'Commentaire',
    information: 'Information',
    application: 'Application',
  },
  User: {
    username: 'CP',
    email: 'E-mail',
    firstName: 'Prénom',
    lastName: 'Nom',
    enabled: 'Actif',
    createdTimestamp: 'Date d\'enregistrement',
    id: 'ID',
    can_delegate: 'Rôle',
    action: 'Action',
    users: 'Utilisateurs',
    noResults: 'Aucun utilisateur trouvé',
    enableSuccess: 'L\'utilisateur a bien été activé',
    disableSuccess: 'L\'utilisateur a bien été désactivé',
    search: 'Rechercher',
    create: 'Créer un nouvel utilisateur',
    manageGroup: 'Gestion des groupes de',
    memberOf: 'Membre des',
    followingGroups: 'groupes suivants',
    availableGroups: 'Groupes disponibles',
    remove: 'Retirer des',
    writeUsernames: 'Entrez un ou plusieurs CP SNCF',
    noUsername: 'Pas de CP SNCF ?',
    addUsername: 'Ajouter le CP',
    addUsernames: 'Ajouter les',
    creationSuccess: 'Un utilisateur a bien été créé',
    usernameError: 'entrée n\'a pas pu être ajoutée à la liste :',
    usernameErrors: 'entrées n\'ont pas pu être ajoutées à la liste :',
    createThe: 'Créer les',
    createdUsers: 'utilisateurs créés',
    createUsername: 'Créer un utilisateur',
    returnToForm: 'Retour au formulaire par CP',
    creationFail: 'Aucun utilisateur créé',
    wrongEmail: 'L\'email entré est au mauvais format (mail@exemple.fr)',
    removeFromGroup: 'Retirer du groupe',
    noGroups: 'Membre d\'aucun groupe :',
    memberOfOne: 'Membre d\'un groupe :',
    notFound: 'Utilisateur non trouvé',
    selectedLevelsNotMatching: "Les niveaux d'accès des groupes sélectionnés ne sont pas compatible avec ce groupe",
    selectLevels: "Choisir le niveau d'habilitation : ",
    removeLevels: (name: string) => `Retirer des habilitations de ${name}`,
    removeLevelFromGroups: (nb: number) => `${nb > 1 ? `Retirer les habilitations de ${nb} applications` : "Retirer les habilitations d'une application"}`,
    addLevels: (name: string) => `Habiliter ${name} aux applications`,
    addLevelToGroups: (name: string, nb: number) => `${nb > 1 ? `Habiliter ${name} aux ${nb} applications` : `Habiliter ${name} à l'application`}`,
  },
  Group: {
    groups: 'Groupes',
    apps: 'Applications',
    app: 'Application',
    notFound: 'Pas de groupes trouvés',
    removeAuthorization: 'Retirer les habilitations',
    admin: 'Admin',
    member: 'Accès à l\'application',
    addToGroup: 'Habiliter un utilisateur',
    removedUser: 'Utilisateur retiré du groupe',
    addedUser: 'Utilisateur ajouté au groupe',
    modificationSuccess: 'Groupes modifiés avec succès',
    updateSuccess: 'Groupe modifié avec succès',
    partialModification: 'Tous les groupes n\'ont pas pu être modifiés',
    assignAdmin: (nb: number, level: number) => (nb > 1 ? `Ajouter ${nb} niveaux ${level}` : 'Ajouter l\'administrateur'),
    removeAdmin: 'Supprimer l\'administrateur',
    assignMember: (nb: number) => (nb > 1 ? `Habiliter les ${nb} utilisateurs` : 'Habiliter l\'utilisateur'),
    modifiedUser: 'Utilisateur modifié',
    searchUserToAdd: 'Rechercher un utilisateur Cerbere à ajouter au groupe',
    importUsernames: 'Importer une liste de CP',
    importInfo: '(Séparez les CP par un espace ou un retour à la ligne)',
    addedUsers: 'utilisateurs ajoutés à',
    addFail: 'Aucun utilisateur ajouté',
    usernameError: 'CP n\' a pas pu être ajouté à la liste :',
    usernameErrors: 'CP n\'ont pas pu être ajoutés à la liste :',
    infoCreatedUser: 'CP a été créé automatiquement dans cerbere, vous pouvez le retrouver dans la liste d’utilisateurs :',
    infoCreatedUsers: 'CP ont été créés automatiquement dans cerbere, vous pouvez les retrouver dans la liste d’utilisateurs :',
    total: 'au total',
    searchUsers: 'Rechercher dans les utilisateurs',
    copyMail: 'copier la liste',
    name: 'Nom du groupe',
    parameters: 'Paramètres du groupe ',
    description: 'Description',
    level1: 'Niveau 1',
    level2: 'Niveau 2',
    addAdmin: 'Ajouter un administrateur au groupe',
    showPerm: 'Voir les permissions du groupe',
    assignLevel1: 'Passer en niveau 1',
    assignLevel2: 'Passer en niveau 2',
    groupPerm: 'Permissions de',
    membershipRequest: (nb: number) => `${nb} nouvelle${nb > 1 ? 's' : ''} demande${nb > 1 ? 's' : ''} d'accès`,
    seeRequest: (nb: number) => (nb > 1 ? 'Voir les demandes' : 'Voir la demande'),
    currentRequests: (nb: number) => `demande${nb > 1 ? 's' : ''} d'accès en cours`,
    approve: 'Accepter',
    reject: 'Refuser',
    commentReason: 'Entrez une raison',
    pendingRequest: (nb: number) => `${nb} demande${nb > 1 ? 's' : ''} d'accès en attente`,
    approveRejectRequest: (approveNb: number, rejectNb: number) => `Valider ${approveNb} et rejeter ${rejectNb} demandes`,
    approveRequest: (nb: number) => `Valider ${nb} demande${nb > 1 ? 's' : ''}`,
    rejectRequest: (nb: number) => `Rejeter ${nb} demande${nb > 1 ? 's' : ''}`,
    approvedUsers: (nb: number) => (nb > 1 ? `${nb} personnes vont être habilitées à l'outil :` : '1 personne va être habilitée à l\'outil :'),
    rejectedUsers: (nb: number) => (nb > 1 ? `${nb} personnes vont être refusées :` : '1 personne sera refusée :'),
    requestSummary: 'Récapitulatif de la requête',
    missingApproval: 'Veuillez traiter toutes les demandes d\'accès',
    missingRejectInfo: 'Veuillez entrer un commentaire pour toutes les demandes refusées',
    comment: 'Commentaire',
    addUser: "Habiliter un utilisateur à l'application",
    searchUser: 'Rechercher un utilisateur',
    slug: 'Nom technique : ',
    chooseLevel: "Choisir le niveau d'habilitation :",
  },
  Token: {
    accepted: 'Actif',
    owned: 'Actif',
    pending: 'En attente',
    pending_delete: 'En attente de suppression',
    rejected: 'Refusé',
    disable: 'Désactivé',
    tokens: 'Tokens',
    personal: 'Personnel',
    external: 'Externe',
    service: 'Service',
    showList: 'Voir les permissions',
    permissionList: 'Liste des permissions',
    expired: 'Expiré',
    ask: 'Demander un token',
    name: 'Nom du token',
    willExpire: 'Le token expirera le ',
    askedPermissions: 'Voici les permissions demandées',
    successRequest: 'Demande de token créée',
    selectPermissions: 'Sélectionner les permissions',
    askingReason: 'Raison de la demande',
    reason: 'Raison',
    askMultiple: (nb: number) => (nb > 1 ? `Ajouter ${nb} permissions` : 'Ajouter une permission'),
    modificationRequest: 'Demande de modification',
    outsideToken: "Le token n'est pas modifiable car il n'a pas été créé depuis l'interface Cerbère",
    selectNewPermissions: 'Veuillez sélectionner les nouvelles permissions relatives à ce token :',
    newTokenPermissions: 'Les nouvelles permissions relatives au token',
    validate: 'Valider la sélection',
    permissionCount: (nb: number) => (nb > 1 ? `${nb} permissions sélectionnées` : 'Une permission sélectionnée'),
    noneSelected: 'Pas de permission sélectionnée',
  },
  Help: {
    faq: 'FAQ',
  },
} as const

export default terms
