/* eslint-disable no-restricted-syntax */
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import {
  Checkbox,
  Collapse, IconButton, List, ListItem, ListItemIcon, ListItemText,
} from '@mui/material'
import terms from 'common/terms'
import InfoPanel, { Severity } from 'components/InfoPanel/InfoPanel'
import {
  ChangeEvent, Fragment, ReactElement,
  useState,
} from 'react'
import {
  UserService,
} from 'services/cerbereTypes'
import { UserGroupLevels } from 'services/customTypes'
import { EmptyObject } from 'types'
import Item from './Item'

type Props = {
  services: UserService[];
  mode?: 'user' | 'group';
  setGroups: (groupIds: string[]) => void;
  permissions: UserGroupLevels;
  userPermissions: UserGroupLevels;
  groupList: EmptyObject<string>;
  minCheckableLevel: string[]
  checked: string[];
} & typeof defaultProps

const defaultProps = {
  mode: 'user',
}

export default function GroupList({
  mode, services, setGroups, permissions, userPermissions, groupList, checked, minCheckableLevel,
}: Props): ReactElement {
  const [open, setOpen] = useState<number[]>([])

  const handleToggle = (index: number) => {
    const currentIndex = open.indexOf(index)
    const newOpen = [...open]
    if (currentIndex === -1) {
      newOpen.push(index)
    } else {
      newOpen.splice(currentIndex, 1)
    }
    setOpen(newOpen)
  }
  const checkService = (service: string) => {
    if (groupList[service]?.length) {
      return groupList[service].flatMap(el => (!permissions[el].disabled ? el : [])).every(e => checked.includes(e))
    }
    return false
  }

  const isChecked = (id: string): boolean => checked.includes(id)

  const handleCheck = (_event: ChangeEvent<HTMLInputElement>, values: string[], service: string) => {
    let newChecked = []
    if (!values.length) {
      if (!checkService(service)) {
        const availableGroups = groupList[service].flatMap(el => (!permissions[el].disabled ? el : []))
        newChecked = [...new Set([...checked, ...availableGroups])]
      } else {
        const toRemove = groupList[service]
        newChecked = checked.filter(e => !toRemove.includes(e))
      }
    } else {
      const currentIndex = checked.indexOf(values[values.length - 1])
      if (currentIndex === -1) {
        const toAdd = values
        newChecked = [...new Set([...checked, ...toAdd])]
      } else {
        newChecked = checked.filter(e => e !== values[values.length - 1])
      }
    }

    setGroups(newChecked)
  }

  const getService = (service: UserService, index: number) => {
    let shouldDisplay = false

    if (mode === 'user') {
      groupList[service.service].forEach(group => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { isService, disabled, ...grp } = permissions[group]

        if (Object.values(grp).some(perm => perm)) {
          shouldDisplay = true
        }
      })
    }

    return shouldDisplay || mode === 'group' ? (
      <ListItem
        style={{ width: 'fit-content' }}
        disablePadding
      >
        {mode === 'group' && (
        <ListItemIcon className="checkbox title">
          <Checkbox
            disabled={permissions[service.service].disabled}
            onChange={evt => handleCheck(evt, [], service.service)}
            checked={checkService(service.service)}
            checkedIcon={<IndeterminateCheckBoxIcon />}
          />
        </ListItemIcon>
        )}

        <ListItemText
          className={`list-title-text ${mode === 'group'
         && permissions[service.service].disabled ? 'disabled' : ''}`}
          primary={service.service}
        />
        <IconButton className="expand-icon" onClick={() => handleToggle(index)}>
          {open.indexOf(index) !== -1 ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
      </ListItem>
    ) : <></>
  }

  return (
    <>
      {services?.length === 0 ? (
        <InfoPanel
          title={terms.Group.notFound}
          severity={Severity.warning}
        />
      )
        : (
          <List>
            {services?.map((service, index) => (
              <Fragment key={service.service}>
                {getService(service, index)}
                <Collapse in={open.indexOf(index) !== -1} timeout="auto" unmountOnExit>
                  {service.groups.map(group => (
                    <Item
                      checked={isChecked}
                      service={service.service}
                      handleCheck={handleCheck}
                      key={group.id}
                      groups={group}
                      index={1}
                      showRoles={mode === 'user'}
                      userPermissions={userPermissions}
                      permissions={permissions}
                      minCheckableLevel={minCheckableLevel}
                    />
                  ))}
                </Collapse>
              </Fragment>

            ))}

          </List>
        )}
    </>
  )
}

GroupList.defaultProps = defaultProps
