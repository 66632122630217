import { Chip } from '@mui/material'
import { ReactElement } from 'react'
import './CustomChip.scss'
import { Group, User } from 'services/cerbereTypes'

// to refacto types
type Props = {
  object: User | Group;
  onDelete: (object: User | Group) => void
}

export default function CustomChip({ object, onDelete }: Props): ReactElement {
  return (
    <Chip
      className="user-chip"
      label={'displayName' in object ? object.displayName : (object as Group).display_name}
      onDelete={() => {
        onDelete(object)
      }}
    />
  )
}
