import { InputBase } from '@mui/material'
import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg'
import terms from 'common/terms'
import CustomTable from 'components/CustomTable/CustomTable'
import ModalWrapper from 'components/ModalWrapper/ModalWrapper'
import { DateTime } from 'luxon'
import { ReactElement, useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { resetToken, TokenState } from 'reducers/token'
import TokenServices from 'services/TokenServices'
import { AppDispatch, RootState, store } from 'Store'
import './AskTokenPopup.scss'
import SearchInput from 'components/SearchInput/SearchInput'
import { debounce } from 'lodash'
import columnsHeader from './const'

type Props = {
  open: boolean;
  toggle: () => void;
}
const debouncedSearch = debounce(
  (val: string) => store.dispatch(TokenServices.getPermissions(val)),
  200,
)
export default function AskTokenPopup({ open, toggle }: Props): ReactElement {
  const dispatch = useDispatch<AppDispatch>()
  const {
    permissions, askPermissions, selectedToken,
  } = useSelector((state: RootState) => state.tokens as TokenState)
  const expiryDate = DateTime.fromJSDate(new Date()).plus({ months: 3 })
  const { account } = useSelector((state: RootState) => state.user)
  const [tokenName, setTokenName] = useState(selectedToken?.slug.replace(`${account.username}__`, '') || '')
  const [step, setStep] = useState(selectedToken ? 2 : 1)
  const [reason, setReason] = useState('')

  const resetForm = () => {
    setStep(1)
    dispatch(resetToken())
    setTokenName('')
    setReason('')
  }

  useEffect(() => {
    dispatch(TokenServices.getPermissions(''))
  }, [])

  useEffect(() => {
    if (!open) {
      resetForm()
    }
  }, [open])

  useEffect(() => {
    if (selectedToken) {
      setStep(2)
      setTokenName(selectedToken.slug.replace(`${account.username}__`, ''))
      dispatch(TokenServices.getTokenInfo({ id: selectedToken.id }))
    }
  }, [selectedToken])

  const askToken = () => {
    dispatch(TokenServices.createRequest({
      slug: tokenName,
      permissions: askPermissions,
      reason,
      expires_on: expiryDate.toISODate() as string,
      token_id: selectedToken?.id || undefined,
    })).unwrap().then(() => toggle()).catch(/* error */)
  }

  const backButton = (
    <Button
      className="button cancel"
      variant="contained"
      onClick={() => setStep(step - 1)}
    >
      {terms.Common.back}
    </Button>
  )

  const getConfirmButton = () => {
    switch (step) {
      case 1:
        return !selectedToken ? (
          <Button
            disabled={tokenName.trim() === ''}
            className="button"
            variant="contained"
            onClick={() => setStep(2)}
          >
            {terms.Common.continue}
          </Button>
        ) : null

      case 2:
        return (
          <div className="actions">
            {!selectedToken && backButton}
            <Button
              disabled={reason.trim() === ''}
              className="button"
              variant="contained"
              onClick={() => setStep(3)}
            >
              {terms.Common.continue}
            </Button>
          </div>
        )

      case 3:
        return (
          <div className="actions">
            {backButton}
            <Button
              disabled={!askPermissions.length}
              className="button"
              variant="contained"
              onClick={() => setStep(4)}
            >
              {terms.Token.validate}
            </Button>
          </div>
        )

      case 4:
        return (
          <div className="actions">
            {backButton}
            <Button className="button" variant="contained" onClick={askToken}>{terms.Common.confirm}</Button>
          </div>
        )

      default:
        return <></>
    }
  }

  const getTitle = () => {
    switch (step) {
      case 1:
        return selectedToken ? '' : terms.Token.ask

      case 2:
        return terms.Token.askingReason

      case 3:
        return selectedToken ? terms.Token.modificationRequest : terms.Token.selectPermissions

      case 4:
        return terms.Common.confirmation

      default:
        return terms.Token.ask
    }
  }
  const renderApp = () => {
    switch (step) {
      case 1:
        return !selectedToken ? (
          <>
            <div className="container">
              <div className="label">{terms.Token.name}</div>
              <InputBase
                value={tokenName}
                className="field"
                onChange={evt => {
                  setTokenName(evt.target.value)
                }}
              />
            </div>

            <div className="d-flex expiry w-100">
              <WarningIcon width="20" height="20" fill="#546EFE" />
              <div className="date-limit">
                {`${terms.Token.willExpire} ${expiryDate.toFormat('dd/MM/yyyy')}`}
              </div>
            </div>

          </>
        ) : null

      case 2:
        return (
          <div className="container">
            <div className="label">{terms.Token.reason}</div>
            <InputBase
              className="field multiline"
              value={reason}
              multiline
              rows={5}
              onChange={evt => {
                setReason(evt.target.value)
              }}
            />
          </div>
        )
      case 3:
        // eslint-disable-next-line no-case-declarations
        const modifyInfo = selectedToken ? (
          <div className="modify-info">

            <div className="text">{terms.Token.selectNewPermissions}</div>
          </div>
        ) : <></>

        return (
          <>
            {modifyInfo}
            <SearchInput value="" onChange={e => debouncedSearch(e)} />
            <CustomTable
              values={permissions}
              loader={false}
              count={permissions.length}
              pagination={false}
              columns={columnsHeader}
              changePage={() => undefined}
            />
            <div className="permission-count">
              {askPermissions.length ? terms.Token.permissionCount(askPermissions.length)
                : terms.Token.noneSelected }
            </div>
          </>

        )

      case 4:
        return (
          <div className="confirmation">

            <div className="reason">

              <div className="title">{`${terms.Token.askingReason} :`}</div>

              {`\n${reason}`}

            </div>

            <div className="title">
              {`\n${selectedToken ? terms.Token.newTokenPermissions : terms.Token.askedPermissions} :

              `}
            </div>

            <div className="permissions">
              {askPermissions
                .map(perm => <span key={perm}>{perm}</span>)}
            </div>
          </div>
        )
      default:
        return <></>
    }
  }
  return (
    <ModalWrapper
      className="ask-token"
      toggle={toggle}
      open={open}
      title={getTitle()}
      subtitle={step !== 1 ? tokenName : ''}
      footerButton={false}
      footer={getConfirmButton()}
    >
      {renderApp()}
    </ModalWrapper>
  )
}
