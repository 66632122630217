/* eslint-disable import/prefer-default-export */
import { isAnyOf } from '@reduxjs/toolkit'
import GroupServices from 'services/GroupServices'

export const postPendingMatcher = isAnyOf(
  GroupServices.removeUsersFromGroup.pending,
)

export const postErrorMatcher = isAnyOf(
  GroupServices.removeUsersFromGroup.rejected,
  GroupServices.removeLevel.rejected,
)
