/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/display-name */
import { Button } from '@mui/material'
import terms from 'common/terms'
import CustomCopyCell from 'components/CustomCopyCell/CustomCopyCell'
import { Column } from 'components/CustomTable/types'
import StatusChip from 'components/StatusChip/StatusChip'
import TypeChip from 'components/TypeChip/TypeChip'
import { formatDatetimeToDMY } from 'helpers'
import { ReactElement } from 'react'
import { setTokenModal } from 'reducers/token'
import { Token } from 'services/cerbereTypes'
import TokenServices from 'services/TokenServices'
import { store } from 'Store'
import DisabledToolTip from 'components/DisabledTooltip/DisabledTooltip'
import ModifyButton from './ModifyButton/ModifyButton'

const displayToken = (token: Token) => {
  store.dispatch(TokenServices.getTokenInfo({ id: token.id }))
  store.dispatch(setTokenModal(true))
}

const columnsHeader: Column[] = [
  {
    propertyName: 'slug',
    align: 'left',
    width: '230px',
    copy: false,
    formatter: (o): string => o as string,
    show: () => true,
  },
  {
    propertyName: 'type',
    align: 'left',
    width: '100px',
    copy: false,
    formatter: (o: unknown): ReactElement => (<TypeChip value={o as string} />),
    show: () => true,
  },
  {
    propertyName: 'expires_on',
    align: 'left',
    width: '100px',
    copy: false,
    formatter: (o): string => formatDatetimeToDMY(o as string),
    show: () => true,
  },
  {
    propertyName: 'jwt',
    align: 'left',
    width: '150px',
    copy: true,
    formatter: (o: unknown): ReactElement => (<CustomCopyCell value={o as string} />),
    show: () => true,
  },
  {
    propertyName: 'status',
    align: 'left',
    width: '125px',
    copy: false,
    formatter: (o: unknown): ReactElement => (<StatusChip value={o as string} />),
    show: () => true,
  },
  {
    propertyName: 'actions',
    align: 'left',
    width: '210px',
    copy: false,
    formatter: (_o, token: unknown): ReactElement => {
      const typedToken = token as Token
      return (
        <div className="token-actions">
          <Button
            className={`button ${typedToken.warning ? ' warning' : ''}`}
            onClick={() => displayToken(typedToken)}
            variant="outlined"
          >
            {terms.Token.showList}
          </Button>
          <DisabledToolTip disabled={!typedToken.is_editable} title={typedToken?.warning || ''}>
            <ModifyButton token={typedToken} />
          </DisabledToolTip>

        </div>
      )
    },
    show: () => true,
  },
]

export default columnsHeader
