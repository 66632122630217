/* eslint-disable import/prefer-default-export */
import { isAnyOf } from '@reduxjs/toolkit'
import GroupServices from 'services/GroupServices'
import TokenServices from 'services/TokenServices'
import UserServices from 'services/UserServices'

export const getErrorMatcher = isAnyOf(
  UserServices.getUsers.rejected,
  UserServices.getUser.rejected,
  GroupServices.getGroups.rejected,
  GroupServices.getGroupMembers.rejected,
  TokenServices.getTokens.rejected,
  TokenServices.getTokenInfo.rejected,
  GroupServices.getGroupDetails.rejected,
)
