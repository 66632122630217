import { Tooltip } from '@mui/material'
import { Column } from 'components/CustomTable/types'
import RequestStatusChip from 'components/RequestSummary/RequestStatusChip/RequestStatusChip'
import { QueryStatus, RequestResponse } from 'services/cerbereTypes'

const getClass = (request: RequestResponse) => {
  if (request.status === QueryStatus.failure) {
    return 'information'
  }

  if (request.status === QueryStatus.pending) {
    return ''
  }

  if (request.status === QueryStatus.success) {
    return 'information success'
  }
  return ''
}
// eslint-disable-next-line import/prefer-default-export
export const USER_GROUP_COLUMNS: Column[] = [
  {
    propertyName: 'application',
    align: 'left',
    width: '100px',
    copy: false,
    formatter: (_: unknown, request: unknown) => (request as RequestResponse).username,
    show: () => true,
  },
  {
    propertyName: 'status',
    align: 'left',
    width: '40px',
    copy: false,
    formatter: (_: unknown, r: unknown) => <RequestStatusChip value={(r as RequestResponse).status} />,
    show: () => true,
  },
  {
    propertyName: 'information',
    align: 'left',
    width: '130px',
    copy: false,
    formatter: (_: unknown, request: unknown) => (
      <Tooltip title={(request as RequestResponse).information}>
        <span className={getClass(request as RequestResponse)}>{(request as RequestResponse).information}</span>
      </Tooltip>
    ),
    show: () => true,
  }]
