import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import GroupServices from 'services/GroupServices'
import TokenServices from 'services/TokenServices'
import UserServices from 'services/UserServices'
import { ResponseError } from 'types'
import { allErrorsMatcher, allPendingsMatcher } from './matchers/matchers'

export enum FeedbackType {
  validation = 'validation'
}

export interface FeedbackState {
  feedback?: ResponseError;
  message: string;
  messageReady?: boolean;

}

const initialState: FeedbackState = {
  feedback: undefined,
  message: '',
  messageReady: false,
}

export const feedbackSlice = createSlice({
  name: 'feedback',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<ResponseError | undefined>) => {
      state.feedback = action.payload
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload
    },
    setMessageReady: (state, action: PayloadAction<boolean>) => {
      state.messageReady = action.payload
    },
  },
  extraReducers: builder => {
    // Update handling
    builder.addCase(UserServices.enableUser.fulfilled, state => {
      state.feedback = { code: 200, data: { message: 'User.enableSuccess' } }
    })
    builder.addCase(UserServices.disableUser.fulfilled, state => {
      state.feedback = { code: 200, data: { message: 'User.disableSuccess' } }
    })
    builder.addCase(UserServices.enableUser.pending, state => {
      state.feedback = { code: 202, data: { message: 'Common.pendingQuery' } }
    })
    builder.addCase(UserServices.disableUser.pending, state => {
      state.feedback = { code: 202, data: { message: 'Common.pendingQuery' } }
    })
    builder.addCase(UserServices.createUser.rejected, (state, action) => {
      if (action.payload?.code === 500) {
        state.feedback = { code: 500, data: {} }
      }
    })

    builder.addCase(GroupServices.removeUsersFromGroup.fulfilled, state => {
      state.feedback = { code: 200, data: { message: 'Group.removedUser' } }
    })
    builder.addCase(UserServices.assignToGroups.fulfilled, state => {
      state.feedback = { code: 200, data: { message: 'Group.modificationSuccess' } }
    })
    builder.addCase(UserServices.assignToGroups.rejected, (state, action) => {
      if (action.payload?.code === 400) {
        state.feedback = { code: 400, data: { message: 'Group.partialModification' } }
      }
    })
    builder.addCase(GroupServices.removeLevel.fulfilled, state => {
      state.feedback = { code: 200, data: { message: 'Group.modifiedUser' } }
    })

    builder.addCase(GroupServices.addUsersToGroup.rejected, (state, action) => {
      if (action.payload?.code !== 400) {
        state.feedback = { code: action.payload?.code || 400, data: {} }
      }
    })
    builder.addCase(GroupServices.modifyInfo.fulfilled, state => {
      state.feedback = { code: 200, data: { message: 'Group.updateSuccess' } }
    })
    builder.addCase(GroupServices.approveRejectRequest.rejected, (state, action) => {
      if (action.payload?.code === 500) {
        state.feedback = { code: 500, data: {} }
      }
    })
    builder.addCase(GroupServices.assignToGroupWithLevel.fulfilled, (state, action) => {
      if (!action.meta.arg?.multipleAssign) {
        state.feedback = { code: 200, data: { message: 'Group.modifiedUser' } }
      }
    })
    builder.addCase(GroupServices.assignToGroupWithLevel.rejected, (state, action) => {
      if (!action.meta.arg?.multipleAssign) {
        state.feedback = { code: action.payload?.code || 400, data: {} }
      }
    })
    builder.addCase(TokenServices.createRequest.rejected, (state, action) => {
      state.messageReady = !!action.payload?.data?.error
      state.feedback = {
        code: action.payload?.code || 400,
        data: { message: action.payload?.data?.error },
      }
    })
    builder.addCase(TokenServices.createRequest.fulfilled, state => {
      state.feedback = {
        code: 200,
        data: { message: 'Token.successRequest' },
      }
    })
    // Error Handling
    builder.addMatcher(allErrorsMatcher, (state, action) => {
      state.feedback = action.payload
    })
    builder.addMatcher(allPendingsMatcher, state => {
      state.feedback = { code: 202, data: { message: 'Common.pendingQuery' } }
    })
  },
})

export const { setError, setMessage, setMessageReady } = feedbackSlice.actions

export default feedbackSlice.reducer
