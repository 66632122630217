import terms from 'common/terms'
import CustomButton from 'components/CustomButton/CustomButton'
import SearchInput from 'components/SearchInput/SearchInput'
import TokenPopup from 'components/AskTokenPopup/AskTokenPopup'
import { debounce } from 'lodash'
import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { modifyToken, setTokenSearch } from 'reducers/token'
import { RootState, store } from 'Store'
import './TokenBar.scss'

const debouncedSearch = debounce(
  (val: string) => store.dispatch(setTokenSearch(val)),
  200,
)
type Props = {
  length: string;
}
export default function TokenBar({ length }: Props): ReactElement {
  const dispatch = useDispatch()
  const { tokensLoading, tokenSearch, selectedToken } = useSelector((state: RootState) => state.tokens)
  const [showPopup, setShowPopup] = useState(false)
  const toggle = () => {
    setShowPopup(state => !state)
  }

  const closePopup = () => {
    dispatch(modifyToken(undefined))
    setShowPopup(false)
  }
  const handleChange = (value: string) => {
    if (!value) {
      dispatch(setTokenSearch(value))
      return
    }
    debouncedSearch(value)
  }

  return (
    <div className="token-bar">

      <TokenPopup
        toggle={closePopup}
        open={showPopup || selectedToken}
      />
      <div className="token-count">
        {!tokensLoading && (
          <span>
            {`${length} ${(terms.Token.tokens).toLowerCase()}`}
          </span>
        )}

      </div>

      <div className="token-buttons-container">
        <SearchInput value={tokenSearch} onChange={newValue => handleChange(newValue)} />
        <CustomButton
          onClick={toggle}
          value={terms.Token.ask}
        />
      </div>
    </div>
  )
}
