import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit'
import terms from 'common/terms'
import { GridOrder } from 'objects/types'
import {
  Levels, QueryStatus, RemoveResponse, RequestResponse, User, UserCreationResponse,
  UserResponse,
  UserService,
} from 'services/cerbereTypes'
import GroupServices from 'services/GroupServices'
import UserServices from '../services/UserServices'

export interface UserState {
  users: UserResponse,
  usersLoading: boolean;
  userSearch: string;
  showUserGroup: string;
  userInfo: Partial<User>;
  userAssignLoading: boolean;
  userGroupSearch: string | null;
  showModal: boolean,
  createUserLoading: boolean;
  createdUsers: UserCreationResponse;
  sort: GridOrder;
  services: UserService[];
  requestsSummary: RequestResponse[];
}

const initialCreation = {
  users_creation_fail: [], users_creation_success: [],
}
const initialState: UserState = {
  users: {
    results: [], count: 0, page_size: 20, next: null, previous: null,
  },
  usersLoading: true,
  userSearch: '',
  showUserGroup: '',
  userInfo: {},
  userAssignLoading: false,
  userGroupSearch: null,
  showModal: false,
  createUserLoading: false,
  createdUsers: initialCreation,
  sort: {
    field: '',
    sort: undefined,
  },
  services: [],
  requestsSummary: [],
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setSearch: (state, action: PayloadAction<string>) => {
      state.userSearch = action.payload
    },
    setCreateModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload
    },
    setUserGroup: (state, action: PayloadAction<string>) => {
      state.showUserGroup = action.payload
    },
    setUserInfo: (state, action: PayloadAction<User>) => {
      state.userInfo = action.payload
    },
    setUserGroupSearch: (state, action: PayloadAction<string | null>) => {
      state.userGroupSearch = action.payload
    },
    setSort: (state, action: PayloadAction<GridOrder>) => {
      state.sort = action.payload
    },
    resetUserSearch: state => {
      state.userSearch = ''
      state.userGroupSearch = ''
      state.showUserGroup = ''
    },
    resetRequests: state => {
      state.requestsSummary = []
    },
  },
  extraReducers: builder => {
    builder.addCase(UserServices.getUsers.fulfilled, (state, action) => {
      state.users = action.payload
      state.usersLoading = false
    })
    builder.addCase(UserServices.getUsers.pending, state => {
      state.usersLoading = true
    })
    builder.addCase(UserServices.getUser.fulfilled, (state, action) => {
      state.userInfo = action.payload
      state.usersLoading = false
    })
    builder.addCase(UserServices.getUser.pending, state => {
      state.usersLoading = true
    })
    builder.addCase(UserServices.enableUser.fulfilled, (state, action) => {
      state.users.results = state.users.results.map(user => (user.id === action.payload.id
        ? { ...user, enabled: true } : user))
    })
    builder.addCase(UserServices.disableUser.fulfilled, (state, action) => {
      state.users.results = state.users.results.map(user => (user.id === action.payload.id
        ? { ...user, enabled: false } : user))
    })
    // builder.addCase(UserServices.assignToGroups.fulfilled, (state, action) => {
    //   state.userAssign = action.payload
    //   state.userAssignLoading = false
    // })
    // builder.addCase(UserServices.assignToGroups.pending, state => {
    //   state.userAssignLoading = true
    // })
    // builder.addCase(UserServices.assignToGroups.rejected, (state, action) => {
    //   if (action.payload?.data.groups_assign_fail) {
    //     state.userAssign = action.payload.data as AssignResponse
    //   }
    //   state.userAssignLoading = false
    // })

    builder.addCase(UserServices.createUser.pending, state => {
      state.createdUsers = initialCreation
      state.createUserLoading = true
    })
    builder.addCase(UserServices.createUser.fulfilled, (state, action) => {
      state.createdUsers.users_creation_success = action.payload as string[]
      state.createdUsers.users_creation_fail = []
      state.createUserLoading = false
    })
    builder.addCase(UserServices.createUser.rejected, (state, action) => {
      if (action.payload?.code === 400) {
        state.createdUsers = action.payload.data
      }
      state.createUserLoading = false
    })
    builder.addCase(UserServices.getUserGroups.fulfilled, (state, action) => {
      state.services = action.payload
    })
    builder.addMatcher(isAnyOf(UserServices.assignToGroups.fulfilled, UserServices.removeFromGroups.fulfilled),
      (state, action) => {
        state.requestsSummary = state.requestsSummary.map(req => {
          const newReq = action.payload.successes.find(suc => suc.groupId === req.userId)

          if (newReq) {
            return { ...req, status: QueryStatus.success }
          }
          return req
        })
        state.userAssignLoading = false
      })
    builder.addMatcher(isAnyOf(UserServices.removeFromGroups.pending, UserServices.assignToGroups.pending),
      (state, action) => {
        const { meta: { arg } } = action

        state.userAssignLoading = true
        state.requestsSummary = arg.groups.map(data => ({
          username: data, userId: data, information: terms.Group.member, status: QueryStatus.pending,
        }))
      })
    builder.addMatcher(isAnyOf(UserServices.removeFromGroups.rejected, UserServices.assignToGroups.rejected),
      (state, action) => {
        const data = action.payload?.data as {error: string } & RemoveResponse

        if (action.payload?.code !== 400) return

        state.requestsSummary = state.requestsSummary.map(req => {
          const success = data?.successes?.find(suc => suc.groupId === req.userId
        && req.information === terms.Group.member)
          const failure = data?.failures?.find(suc => suc.groupId === req.userId
        && req.information === terms.Group.member)
          if (success) {
            return { ...req, status: QueryStatus.success }
          }
          if (failure) {
            return { ...req, information: failure.error || '', status: QueryStatus.failure }
          }
          return req
        })
        state.userAssignLoading = false
      })

    builder.addMatcher(isAnyOf(GroupServices.removeLevel.fulfilled, GroupServices.assignToGroupWithLevel.fulfilled),
      (state, action) => {
        const lvl = action.meta.arg.level === Levels.level1 ? terms.Group.level1 : terms.Group.level2

        state.requestsSummary = state.requestsSummary.map(req => {
          if (req.userId === action.meta.arg.groupId && req.information === lvl) {
            return { ...req, status: QueryStatus.success }
          }

          return req
        })
      })
    builder.addMatcher(isAnyOf(GroupServices.removeLevel.pending, GroupServices.assignToGroupWithLevel.pending),
      (state, action) => {
        const { meta: { arg } } = action

        state.requestsSummary.push({
          username: '',
          userId: arg.groupId,
          information: arg.level === Levels.level1 ? terms.Group.level1 : terms.Group.level2,
          status: QueryStatus.pending,
        })
      })
    builder.addMatcher(isAnyOf(GroupServices.removeLevel.rejected, GroupServices.assignToGroupWithLevel.rejected),
      (state, action) => {
        const { arg } = action.meta
        const lvl = arg.level === Levels.level1 ? terms.Group.level1 : terms.Group.level2

        state.requestsSummary = state.requestsSummary.map(req => {
          if (req.userId === arg.groupId && req.information === lvl) {
            return { ...req, status: QueryStatus.failure, information: `${action.payload?.data.error}` }
          }
          return req
        })
      })
  },
})

export const {
  setSearch,
  setUserGroup,
  setUserInfo,
  setUserGroupSearch,
  setCreateModal,
  setSort,
  resetUserSearch,
  resetRequests,
} = usersSlice.actions

export default usersSlice.reducer
