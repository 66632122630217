import { Chip } from '@mui/material'
import terms from 'common/terms'
import { ReactElement } from 'react'
import { AccessTypes } from 'services/cerbereTypes'
import './RoleChip.scss'

type Props = {
  access: AccessTypes;
}
export default function RoleChip({ access }: Props): ReactElement {
  const getLevel = () => {
    if (access.level2) {
      return <Chip className="chip level2" label={terms.Group.level2} />
    }

    if (access.level1) {
      return <Chip className="chip level1" label={terms.Group.level1} />
    }
    return <></>
  }
  return (
    <div className="roles">
      {access.member && <Chip className="chip member" label={terms.Group.member} /> }

      {getLevel()}
    </div>

  )
}
