import { UnknownObject } from 'types'

/* eslint-disable camelcase */
export type User = {
  id: string;
  lastName?: string;
  username: string;
  displayName?: string;
  firstName?: string;
  email?: string;
  createdTimestamp: string;
  enabled?: boolean;
  action?: string;
  emailVerified: boolean;
  is_member?: boolean;
  requiredAction: string[];

}

interface Pagination {
  count: number;
  page_size: number;
  next: string | null;
  previous: string | null;
  results: UnknownObject[];
}
export interface UserResponse extends Pagination {
  results: User[];
}

export interface UserFailure {
  cp: string;
  errorMessage: string;
}

export interface UserCreationResponse {
  users_creation_success?: string[];
  users_creation_fail?: UserFailure[]
}

export type Group = {
  id: string;
  name: string;
  path: string;
  members_count: number;
  is_member: boolean;
  children?: Group[];
  description: string;
  display_name: string;
  requests_count: number;
  slug: string;

}

export type AccessTypes = {
  level1: boolean;
  level2: boolean;
  member: boolean;
}
export type UserGroupWithLevels = {
  has_access_types: AccessTypes;
  displayName: string;
  id: string;
  name: string;
  is_member: boolean;
  children?: UserGroupWithLevels[];
  slug: string;
}

export type GroupDetails = {
  display_name: string;
  description: string;
  warning: string;
  permissions: Permission[];
  level1_users: SimpleUser[];
  level2_users: SimpleUser[];
  slug: string;
}

export type Permission = {
  client_name: string;
  role_name: string
  doc_link: string;
}

export type SimpleUser = {
  id: string;
  display_name: string;
}

export type UserService = {
  service: string;
  groups: UserGroupWithLevels[]
}

export type GroupService = {
  project_name: string;
  groups: Group[]
}

export type AssignResponse = {
  successes: {userId: string, groupId: string}[];
  failures: {userId: string, groupId: string}[];
}

export type AssignPayload = {
  groupId: string;
  user: SimpleUser;
  level: string;
  multipleAssign?: boolean;
  userId?: string;
}

export type Service = {
  groups: Group[]
}

export type ServiceResponse = {
  results: Service[];
  count: string;
}

export type UserAssignResponse = {
  users_assign_success: string[];
  users_creation_success: string[];
  users_assign_fail: UserFailure[];
  users_creation_fail: UserFailure[];
}

export type Token = {
  slug: string;
  id: string;
  type: string;
  jwt: string;
  expires_on: string;
  status: string;
  warning?: string;
  source: string;
  is_editable: boolean;
}

export type TokenPermission = {
  client_name: string;
  role_name: string;
  doc_link: string;
}

export type TokenInfo = {
  permissions: TokenPermission[];
  warning: string;
}

export type GroupPayload = {
  page: number;
  groupId: string;
  search?: string;
  pageSize?: number;
  fetchingMail?: boolean;
}

export enum Levels {
  level1 = 'level1',
  level2 = 'level2'
}

export type GroupUpdate = {
  id: string;
  display_name: string;
  description: string;
}

export type GroupRequest = {
  user_id: string;
  username: string;
  user_display_name: string;
  group_id: string;
  group_slug: string;
  group_display_name: string;
  reason: string;
  level: Levels;
}
export interface MembershipRequest extends Pagination {
  results: GroupRequest[]
}

export enum RequestStatus {
  approve = 'approve',
  reject = 'reject'
}

export type RequestState = { userId: string, state: RequestStatus, reason?: string, user_display_name: string; }

export enum QueryStatus {
  success = 'success',
  pending = 'pending',
  failure = 'failure',
}

export type RequestResponse = {
  userId: string;
  username: string;
  information: string;
  status: QueryStatus;
}

type RemoveUser = {
  userId: string;
  groupId: string;
  error?: string;
}

export type RemoveResponse = {
  successes: RemoveUser[];
  failures?: RemoveUser[];
}

export type TokenRequest = {
  slug: string;
  permissions: string[];
  expires_on: string;
  reason: string;
  token_id?: string;
}
