import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import terms from 'common/terms'
import {
  Group, GroupDetails,
  MembershipRequest, QueryStatus,
  RemoveResponse,
  RequestResponse, RequestState,
  ServiceResponse, User, UserAssignResponse, UserResponse,
} from 'services/cerbereTypes'
import GroupServices from 'services/GroupServices'
import UserServices from 'services/UserServices'
import { UnknownObject } from 'types'

export interface GroupState {
  services: ServiceResponse;
  servicesLoading: boolean;
  assignGroupLoading: boolean;
  groupsLoading: boolean;
  groupSearch: string;
  selectedGroup: Group;
  groupMembers: UserResponse;
  membersLoading: boolean;
  memberSearch: string;
  addUsersMode: boolean;
  userRemoved: boolean;
  userStatusChanged: boolean;
  groupModal: boolean;
  assignUsersLoading: boolean
  assignedUsers: UserAssignResponse;
  emailList: UserResponse;
  emailLoading: boolean;
  details: GroupDetails;
  detailsLoading: boolean;
  openedAccordions: number[];
  membershipRequests: MembershipRequest;
  requestApprovals: RequestState[];
  requestSummary: RequestResponse[];
  userAdded: RequestResponse[];
}

const initialGroup: Group = {
  id: '',
  name: '',
  path: '',
  children: [],
  members_count: 0,
  is_member: false,
  description: '',
  display_name: '',
  requests_count: 0,
  slug: '',
}

const initialAssign: UserAssignResponse = {
  users_creation_fail: [], users_assign_fail: [], users_assign_success: [], users_creation_success: [],
}

const EMPTY_PAGINATION = {
  results: [], count: 0, page_size: 0, next: null, previous: null,
}

const INITIAL_DETAILS: GroupDetails = {
  display_name: '', description: '', warning: '', permissions: [], level1_users: [], level2_users: [], slug: '',
}

const initialState: GroupState = {
  services: { results: [], count: '0' },
  servicesLoading: false,
  assignGroupLoading: false,
  groupsLoading: true,
  groupSearch: '',
  selectedGroup: initialGroup,
  groupMembers: EMPTY_PAGINATION,
  membersLoading: false,
  memberSearch: '',
  addUsersMode: false,
  userRemoved: false,
  userStatusChanged: false,
  groupModal: false,
  assignUsersLoading: false,
  assignedUsers: initialAssign,
  emailList: EMPTY_PAGINATION,
  emailLoading: false,
  details: INITIAL_DETAILS,
  detailsLoading: false,
  openedAccordions: [],
  membershipRequests: {
    results: [],
    count: 0,
    page_size: 0,
    next: null,
    previous: null,
  },
  requestApprovals: [],
  requestSummary: [],
  userAdded: [],
}

export const groupsSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroupSearch: (state, action: PayloadAction<string>) => {
      state.groupSearch = action.payload
    },
    setSelectedGroup: (state, action: PayloadAction<Group | undefined>) => {
      if (state.selectedGroup.id === action.payload?.id) return

      if (action.payload === undefined) {
        state.selectedGroup = initialGroup
      } else {
        state.selectedGroup = action.payload
        state.membersLoading = true
      }
    },
    setMemberSearch: (state, action: PayloadAction<string>) => {
      state.memberSearch = action.payload
    },
    setAddUsersMode: (state, action: PayloadAction<boolean>) => {
      state.addUsersMode = action.payload
    },
    setGroupModal: (state, action: PayloadAction<boolean>) => {
      state.groupModal = action.payload
    },
    resetGroupSearch: state => {
      state.groupSearch = ''
      state.memberSearch = ''
    },
    resetGroups: state => {
      state.memberSearch = ''
      state.services = { results: [], count: '0' }
      state.selectedGroup = initialGroup
      state.details = INITIAL_DETAILS
    },
    resetEmailList: state => {
      state.emailList = EMPTY_PAGINATION
    },
    setOpenedAccordions: (state, action: PayloadAction<number[]>) => {
      state.openedAccordions = action.payload
    },
    updateApproval: (state, action: PayloadAction<RequestState>) => {
      const userRequest = state.requestApprovals.find(elem => elem.userId === action.payload.userId)
      if (userRequest) {
        state.requestApprovals = state.requestApprovals.map(request => (request.userId === action.payload.userId
          ? action.payload : request))
      } else {
        state.requestApprovals.push(action.payload)
      }
    },
    resetApproval: state => {
      state.requestApprovals = []
      state.requestSummary = []
    },
    resetUsers: state => {
      state.userAdded = []
    },
  },
  extraReducers: builder => {
    builder.addCase(GroupServices.addUsersToGroup.fulfilled, (state, action) => {
      const { payload, meta: { arg } } = action
      const successes: RequestResponse[] = payload.successes.map(user => ({
        userId: user.userId,
        status: QueryStatus.success,
        information: '',
        username: arg.withCp ? user.userId : (arg.users as User[]).find(u => u.id === user.userId)?.displayName || '',
      }))
      state.userAdded = successes
    })
    builder.addCase(GroupServices.addUsersToGroup.rejected, (state, action) => {
      const { payload, meta: { arg } } = action
      if (payload?.code === 500) return

      const data = payload?.data as RemoveResponse
      const successes: RequestResponse[] = data.successes.map(user => ({
        userId: user.userId,
        status: QueryStatus.success,
        information: '',
        username: arg.withCp ? user.userId : (arg.users as User[]).find(u => u.id === user.userId)?.displayName || '',
      }))
      const failures: RequestResponse[] = data.failures?.map(user => ({
        userId: user.userId,
        status: QueryStatus.failure,
        information: user.error || '',
        username: arg.withCp ? user.userId : (arg.users as User[]).find(u => u.id === user.userId)?.displayName || '',
      })) || []
      state.userAdded = [...failures, ...successes]
    })
    builder.addCase(GroupServices.removeUsersFromGroup.pending, state => {
      state.assignGroupLoading = true
    })
    builder.addCase(GroupServices.removeUsersFromGroup.fulfilled, state => {
      state.assignGroupLoading = false
      state.userRemoved = true
    })
    builder.addCase(GroupServices.getGroups.fulfilled, (state, action) => {
      state.services = action.payload
      state.groupsLoading = false
    })
    builder.addCase(GroupServices.getGroups.pending, state => {
      state.groupsLoading = true
    })
    builder.addCase(GroupServices.getGroupMembers.pending, (state, action) => {
      if (action.meta.arg.fetchingMail) {
        state.emailLoading = true
        return
      }
      state.membersLoading = true
      state.userRemoved = false
    })
    builder.addCase(GroupServices.getGroupMembers.fulfilled, (state, action) => {
      if (action.meta.arg.fetchingMail) {
        state.emailList = { ...action.payload, results: [...state.emailList.results, ...action.payload.results] }
        if (!action.payload.next) {
          state.emailLoading = false
        }
        return
      }
      state.groupMembers = action.payload
      state.membersLoading = false
    })
    builder.addCase(GroupServices.getGroupMembers.rejected, state => {
      state.emailLoading = false
      state.membersLoading = false
    })
    // todo regroup in matchers
    builder.addCase(GroupServices.removeLevel.pending, state => {
      state.detailsLoading = true
    })
    builder.addCase(GroupServices.getGroupDetails.pending, state => {
      state.detailsLoading = true
    })
    builder.addCase(GroupServices.getGroupDetails.rejected, state => {
      state.detailsLoading = false
    })
    builder.addCase(GroupServices.removeLevel.rejected, state => {
      state.detailsLoading = false
    })
    builder.addCase(GroupServices.removeLevel.fulfilled, (state, action) => {
      state.detailsLoading = false
      // todo refacto
      const levelKey = `${action.meta.arg.level}_users` as keyof Pick<GroupDetails,
       'level1_users' | 'level2_users'>
      state.details = {
        ...state.details,
        [levelKey]:
        state.details[levelKey].filter(user => user.id !== action.meta.arg.userId),
      }
    })
    builder.addCase(GroupServices.assignToGroupWithLevel.pending, (state, action) => {
      if (action.meta.arg.multipleAssign) {
        const { user } = action.meta.arg
        const newUser: RequestResponse = ({
          userId: user.id,
          status: QueryStatus.pending,
          information: '',
          username: user.display_name,
        })
        state.userAdded.push(newUser)
      }
    })
    builder.addCase(GroupServices.assignToGroupWithLevel.fulfilled, (state, action) => {
      // todo refacto
      const levelKey = `${action.meta.arg.level}_users` as keyof Pick<GroupDetails,
       'level1_users' | 'level2_users'>
      const { id } = action.meta.arg.user

      if (!state.details[levelKey].find(user => user.id === id)) {
        state.details[levelKey].push(action.meta.arg.user)
      }

      if (action.meta.arg.multipleAssign) {
        const { user } = action.meta.arg
        const newUser: RequestResponse = ({
          userId: user.id,
          status: QueryStatus.success,
          information: '',
          username: user.display_name,
        })
        state.userAdded = state.userAdded.map(u => (u.userId === newUser.userId ? { ...newUser } : u))
      }
    })
    builder.addCase(GroupServices.assignToGroupWithLevel.rejected, (state, action) => {
      if (action.payload?.code === 400 || action.payload?.code === 404) {
        if (action.meta.arg.multipleAssign) {
          const payload = action.payload.data
          const { user } = action.meta.arg
          const newUser: RequestResponse = ({
            userId: user.id,
            status: QueryStatus.failure,
            information: (payload).error as string,
            username: user.display_name,
          })
          state.userAdded = state.userAdded.map(u => (u.userId === newUser.userId ? { ...newUser } : u))
        }
      }
    })
    builder.addCase(UserServices.getUsernamesInfo.fulfilled, (state, action) => {
      action.meta.arg.forEach(user => {
        if (!action.payload.find(u => u.username === user)) {
          const newUser: RequestResponse = ({
            userId: user,
            status: QueryStatus.failure,
            information: terms.User.notFound,
            username: user,
          })
          state.userAdded.push(newUser)
        }
      })
    })
    // todo add pending + rejected
    builder.addCase(GroupServices.getGroupDetails.fulfilled, (state, action) => {
      state.detailsLoading = false
      state.details = action.payload
    })
    builder.addCase(GroupServices.modifyInfo.fulfilled, (state, action) => {
      state.details = action.payload
      state.selectedGroup = {
        ...state.selectedGroup,
        description: action.payload.description,
        display_name: action.payload.display_name,
      }
    })
    builder.addCase(GroupServices.getMembershipRequests.fulfilled, (state, action) => {
      state.membershipRequests = action.payload
    })
    builder.addCase(GroupServices.approveRejectRequest.fulfilled, (state, action) => {
      const { meta: { arg } } = action

      state.requestApprovals = state.requestApprovals.filter(request => request.userId !== arg.userId)
      state.membershipRequests = {
        ...state.membershipRequests,
        count: state.membershipRequests.count - 1,
        results:
        state.membershipRequests.results.filter(request => request.user_id
        !== arg.userId || request.group_id !== arg.groupId),
      }

      state.requestSummary.push({
        username: arg.user_display_name, userId: arg.userId, information: '', status: QueryStatus.success,
      })
    })
    builder.addCase(GroupServices.approveRejectRequest.rejected, (state, action) => {
      const { meta: { arg } } = action
      state.requestSummary.push({
        username: arg.user_display_name,
        userId: arg.userId,
        information: (action.payload as UnknownObject)?.error as string || '',
        status: QueryStatus.failure,
      })

      state.requestApprovals = state.requestApprovals.filter(request => request.userId !== arg.userId)
    })
  },
})

export const {
  setGroupSearch, setSelectedGroup, setMemberSearch, setAddUsersMode, setGroupModal,
  resetGroupSearch, resetGroups, resetEmailList, setOpenedAccordions, updateApproval,
  resetApproval, resetUsers,
} = groupsSlice.actions

export default groupsSlice.reducer
