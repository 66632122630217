import {
  styled, Tooltip, tooltipClasses, TooltipProps,
} from '@mui/material'
import { PropsWithChildren, ReactElement } from 'react'

type Props = PropsWithChildren<{
  disabled: boolean;
  title: string;
}>
const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#242830',
    color: '#FFFFFF',
    fontSize: '14px',
  },
}))

export default function DisabledToolTip({ disabled, title, children }: Props): ReactElement {
  return (
    <LightTooltip
      className="info-tooltip"
      disableHoverListener={disabled}
      disableFocusListener={disabled}
      disableInteractive={disabled}
      disableTouchListener={disabled}
      title={title}
    >
      <span>
        {children}
      </span>
    </LightTooltip>
  )
}
